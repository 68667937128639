import request from '@/utils/request'
export function OH_byType(data) {
  return request({
    url: `/open/queryByType`,
    method: 'get',
    params:data
  })
}

export function OH_typeList(id) {
    return request({
      url: `/open/type/list?id=${id}`,
      method: 'get',
    })
}
// export function riskDetail(id) {
//     return request({
//       url: `/open/risk/list?id=${id}`,
//       method: 'get',
//     })
// }
// export function riskList(source,type,page,size) {
//     return request({
//       url: `/open/risk/detail?source=${source}&type=${type}&page=${page}&size=${size}`,
//       method: 'get',
//     })
// }

export function analysisRisk(data) { //风险评估
  return request({
    url: `/open/analysisRisk`,
    method: 'post',
    data
  })
}

export function hisList(data) { //风险历史案例
  return request({
    url: `/open/risk/list`,
    method: 'get',
    params:data
  })
}

export function hisRiskDetils(id) { //风险历史案例详情
  return request({
    url: `/open/risk/detail?id=${id}`,
    method: 'get',
  })
}