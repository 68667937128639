<template>
    <div class="pageBox">
        <div class="bannerBox">
            <div class="topText">
                <div class="titleTop">馆藏文物预防性保护风险管理 </div>
                <div>监测预警 | 风险评估 | 决策支持</div>
            </div>
        </div>
        <div class="SWBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 生物分析评估 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="toolsType">
                <div class="toosBox" @click="entryTool(index)" v-for="(item, index) in toolsList" :key="index" :style="`background-color:${item.color};`">
                    <div class="toosName">{{item.name}}</div>
                    <img :src="item.src" />
                </div>
            </div>
        </div>
        <div style="height: 270px;"></div>
        <div class="HJBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 环境风险评估 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="typeContain">
                <div class="loadBox">
                    <div class="loadLine">
                        数据模板
                        <a :href="url('/数据模板.xlsx')" download="数据模板.xlsx">
                            <el-button size="small" type="primary" circle plain>
                                <svg-icon icon-class="downLoad"/>
                            </el-button>
                        </a>
                    </div>
                </div>
                <div class="formBox2">
                    <div class="topBtns">
                        <!-- <el-button  type="primary" round >基础评估</el-button>
                        <el-button  type="primary" round plain>历史评估</el-button> -->
                    </div>
                    <el-form
                    ref="form"
                    :model="ruleForm"
                    label-width="120px"
                    class="demo-ruleForm"
                    >
                        <el-form-item 
                        label="名称" 
                        prop="name"
                        :rules="{ required: true, message: '名称不能为空', trigger: 'blur' }">
                            <el-input v-model="ruleForm.name" />
                        </el-form-item>
                        <el-form-item 
                        label="博物馆" 
                        prop="museum"
                        :rules="{ required: true, message: '博物馆名称不能为空', trigger: 'blur' }">
                            <el-input v-model="ruleForm.museum" />
                        </el-form-item>
                        <el-form-item 
                        label="文物名称" 
                        prop="culturalname"
                        :rules="{ required: true, message: '文物名称不能为空', trigger: 'blur' }">
                            <el-input v-model="ruleForm.culturalname" />
                        </el-form-item>
                        <el-form-item 
                        label="材质" 
                        prop="texture"
                        :rules="{ required: true, message: '请选择材质', trigger: 'blur' }">
                            <el-select v-model="ruleForm.texture" placeholder="请选择材质">
                                <el-option v-for="(item, index) in textureS" :label="item" :value="item" />
                            </el-select>
                        </el-form-item>
                        <el-form-item 
                        label="文物信息" 
                        prop="detail"
                        :rules="{ required: true, message: '请填写文物信息', trigger: 'blur' }">
                            <el-input type="textarea" v-model="ruleForm.detail" />
                        </el-form-item>
                        <div class="formItem" v-for="(item, index) in ruleForm.datas" :key="index" >
                            <el-form-item 
                            :rules="{ required: true, message: '请选择监测指标', trigger: 'blur' }"
                            label="上传数据" 
                            :prop="'datas.' + index + '.sener'">
                                <el-select v-model="item.sener" placeholder="请选择监测指标">
                                    <el-option v-for="(item, index) in senerS" :key="index" :label="item.label" :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                            :rules="{ required: true, message: '请上传数据文件', trigger: 'blur' }"
                            class="upload-demo" 
                            label=""  
                            label-width="0" 
                            :key="index"  
                            :prop="'datas.' + index + '.file'">
                                <el-upload
                                    v-model:file-list="item.fileList"
                                    accept=".xlsx"
                                    action=""
                                    :http-request="(event)=>httpRequestFile(event, index)"
                                    :before-upload="beforeAvatarUploadFile"
                                    :on-remove="(event)=>removeFile(event, index)"
                                    :limit="1"
                                >
                                    <el-button type="primary">上传文件</el-button>
                                </el-upload>
                            </el-form-item>
                            <el-button type="primary" v-if="index == 0" circle @click="addFile" >
                                <svg-icon icon-class="plus"/>
                            </el-button>
                            <el-button type="primary" v-else circle @click="minuFile(index)" >
                                <svg-icon icon-class="minus"/>
                            </el-button>
                        </div>
                    </el-form>
                    <div class="btnsBox">
                        <el-button type="primary" @click="submit('form')" size="large">
                        风险评估      
                        </el-button>
                        <!-- <el-button  size="large">
                        重置    
                        </el-button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="caseBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 历史案例 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="typeContain PosiContain" style="flex-wrap: wrap;padding-bottom: 20px;">
                <div class="TCpB-right" v-for="(item, index) in Ruleslist">
                    <div class="TCPBR-Name" @click="goEntery(item.id)"  :key="index">
                        {{ item.name }}
                    </div> 
                </div>
            </div>
            <div class="pain">
                <el-pagination
                    layout="total, prev, pager, next"
                    :total="total"
                    :page-size="query.size"
                    :current-page="query.page"
                    @current-change="CurrentChange"
                />
            </div>
            
        </div>
        
    </div>
</template>
<script>
import { analysisRisk, hisList } from '@/api/openHome.js'
export default{
    data(){
        return{
            query:{
                page:1,
                size:10,
            },
            total:0,
            toolsList:[
                {name:'基因对比', src:require('../../assets/loginout/tools-01.png'), color:'#E7F8FF'},
                {name:'霉菌识别', src:require('../../assets/loginout/tools-02.png'), color:'#EAFBF3'},
                {name:'害虫识别', src:require('../../assets/loginout/tools-03.png'), color:'#E7F8FF'},
                // {name:'元数据', src:require('../../assets/loginout/tools-04.png'), color:'#EAFBF3'}
            ],
            Ruleslist:[],
            textureS:['馆藏壁画','青铜','铁器','纸质','纺织品','漆木器','其他'],
            senerS:[
                {label:'湿度',id:'01'},
                {label:'温度',id:'02'},
                {label:'二氧化碳',id:'03'},
                {label:'光照度',id:'04'},
                {label:'紫外辐射强度',id:'05'},
                {label:'有机挥发物总量VOC（异丁烯）',id:'06'},
                {label:'有机污染物',id:'07'},
                {label:'无机污染物',id:'08'},
                {label:'含硫污染物',id:'09'},
            ],
            ruleForm:{
                name:null,
                culturalname:null,
                museum:null,
                detail:null,
                texture:null,
                datas:[
                    {
                        sener:null,
                        fileList:[],
                        file:null,
                    }
                ]
            },

            rules:{

            }
        }
    },
    mounted(){
        this.GetohType()
    },
    methods:{
        CurrentChange(page){
            this.query.page = page
            this.GetohType()
        },
        url(val){
            const ipAddress = window.location.href
            const urlObject = new URL(ipAddress)
            const baseUrl = urlObject.origin 
            
            return baseUrl + val
        },
        GetohType(){
            hisList(this.query).then(r=>{ //历史案例
                this.Ruleslist = r.data.data  
                this.total = r.data.pageInfo.total
            })
        },
        goEntery(id){
            if(id){
                this.$router.push({
                    query:{'riskid':id},
                    path:'/riskDetils'
                })
            }
            
        },
        addFile(){
            this.ruleForm.datas.push({
                sener:null,
                fileList:[],
                file:null,
            })
        },
        minuFile(index){
            this.ruleForm.datas.splice(index, 1);
        },
        entryTool(index){
            if(index==0){
                this.$router.push('/toolsText')
            }else if(index==1){
                this.$router.push('/toolsQuestion')
            }else if(index == 2){
                this.$router.push('/toolsBug')
            }else{
                this.$router.push('/toolsData')
            }
        },
        httpRequestFile(param,index){
            this.ruleForm.datas[index].file = param.file
            this.ruleForm.datas[index].fileList = [param.file]
        },
        beforeAvatarUploadFile(rawFile){
            if (rawFile.size / 1024 / 1024 > 10) {
                ElMessage.error('文件大小不得超过10M!')
                return false
            }
            return true
        },
        removeFile(param,index){
            this.ruleForm.datas[index].file = null
            this.ruleForm.datas[index].fileList = []
        },
        submit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let senerList = this.ruleForm.datas.map(i=>{
                        return i.sener
                    })
                    let senerListNew = new Set(senerList)
                    senerListNew = [...senerListNew]
                    if(senerList.length != senerListNew.length){
                        this.$message.warning("请检查监测指标是否重复!")
                        return
                    }
                    let formData = new FormData()
                    for(let key in this.ruleForm){
                        if(key != 'datas'){
                            formData.append(key,this.ruleForm[key])
                        }else{
                           let type = this.ruleForm[key].map((i)=> {return i.sener}).join(',')
                           this.ruleForm[key].forEach(el => {
                            formData.append('files',el.file)
                           });
                           formData.append('type',type)
                           
                        }
                        
                    }
                    analysisRisk(formData).then(r=>{
                        if(r.code == 200){
                            this.goEntery(r.data.id) 
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:0px;
    background-color:white;
}
.bannerBox{
    width:100%;
    height:280px;
    background-image: url('../../assets/loginout/风险评估.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .topText{
        position: absolute;
        bottom: 100px;
        left: 22%;
        width: 500px;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        color: white;
        font-size: 20px;
        font-style: italic;
        .titleTop{
            font-size: 26px;
        }
    }
}
.formItem{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}
.upload-demo{
    margin-left: 20px;
    margin-right: 20px;
}
.loadLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.SWBox{
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: 240px;
    left: calc(50% - 600px);
    z-index: 3;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #5c5c5c;
    .toolsType{
        width: 100%;
        display: flex;
        justify-content: space-around;
        .toosBox{
            width: 26%;
            height: 150px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
            font-size: 24px;
            border-radius: 10px;
            font-weight: 600;
            .toosName{
                height: 100px;
            }
            img{
                width: 80px;
                height: 80px;
                object-fit: contain;
            }
        }
    }
}
.HJBox{
    width:1200px;
    margin: 0 auto;
    margin-top: 50px;
    .typeContain{
        width: 100%;
        margin-top: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
    .loadBox{
        width: 200px;
        height: 200px;
    }
    .formBox2{
        width: calc(100% - 220px);
        border-left:1px solid rgb(202, 202, 202) ;
        .topBtns{
            text-align: right;
            margin-bottom: 20px;
        }
        .btnsBox{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.caseBox{
    width:1200px;
    margin: 0 auto;
    margin-top: 50px;
    padding-bottom: 80px;
    .PosiContain{
        width: 100%;
        margin-top: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .TCpB-left{
            width: calc(65% - 50px);
            .TCp-bottom{
                width: 100%;
                display: flex;
                justify-content: space-between;
            .TCp-Bottom-left{
                    width:340px;
                    border-bottom: 1px solid rgb(218, 218, 218);
                    .TCPBL-title{
                        cursor: pointer;
                        font-weight: 600;
                        margin:20px 0px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .TCPBL-Msg{
                        font-size: 14px;
                        color: #666;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        text-align: justify;
                        -webkit-line-clamp: 2;
                    }
            }
            .TCp-Bottom-right{
                    width: calc(100% - 360px);
                    .TCPBR-Name{
                        height: 50px;
                        display: flex;
                        cursor: pointer;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid rgb(218, 218, 218);
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
            } 
            }
        }
        .TCpB-right{
            width: calc(50% - 20px);
            .TCPBR-Name{
                height: 50px;
                display: flex;
                cursor: pointer;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgb(218, 218, 218);
                box-sizing: border-box;
            }
        }
        .TCp-title{
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .TCp-left{
                width: 80px;
                height: 100%;
                display:flex;
                justify-content:flex-start;
                align-items: center;
                border-bottom: 5px solid blue;
                box-sizing: border-box;
                font-weight: 600;
            }
            .TCp-right{
                width: calc(100% - 80px);
                height: 100%;
                font-size: 14px;
                cursor: pointer;
                display:flex;
                justify-content:flex-end;
                align-items: center;
                border-bottom: 1px solid rgb(218, 218, 218);
                box-sizing: border-box;
                color: #666;
            }
        } 
    }
    .pain{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
}

.title{
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height:100px;
    color:rgb(200, 195, 195);
    .titleCenter{
        margin:0px 20px;
        font-size:20px;
        color:#333;
        font-weight: 600;
    }
}

</style>